import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { RouteComponentProps } from 'react-router-dom';
export interface StateProps{
  name:string,
  orderId: string,
  date: string,
  price: number,
  color: string,
  quantity: number,
  status: string,
  position: number,
  completed: boolean,
  delivered: string,
  loading: boolean,
  myId: number,
  partner: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props{
  navigation: any;
  id: string;
  // Customizable Area Start
  history: RouteComponentProps["history"];
  loading: boolean;
  token: string;
  location:RouteComponentProps["history"]["location"];
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  token: string;
  orderId: string,
  date: string,
  price: number,
  name: string,
  color: string,
  quantity: number,
  status: string,
  position: number,
  completed: boolean,
  delivered: string,
  loading: boolean,
  myId: number,
  partner: string,
  

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class DeliveryEstimatorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrderStatusCallId: string = "";
  getOrdersAPICallId: string = "";
  labels = ["In process", "Shipped", "On the way", "Delivered"];
  positions = 0
  OrderID=sessionStorage.getItem('OrderID')
  //Customizable Area End
  customStyles: object = {
    stepIndicatorSize: 25,
    currentStepIndicatorSize: 35,
    separatorStrokeWidth: 3,
    currentStepStrokeWidth: 5,
    stepStrokeCurrentColor: 'red',
    separatorFinishedColor: '#7B4397',
    separatorUnFinishedColor: 'grey',
    stepIndicatorFinishedColor: '#7B4397',
    stepIndicatorUnFinishedColor: '#aaaaaa',
    stepIndicatorCurrentColor: '#ffffff',
    stepIndicatorLabelFontSize: 15,
    currentStepIndicatorLabelFontSize: 18,
    stepIndicatorLabelCurrentColor: '#000000',
    stepIndicatorLabelFinishedColor: '#ffffff',
    stepIndicatorLabelUnFinishedColor: 'rgba(255,255,255,0.5)',
    labelColor: '#666666',
    labelSize: 15,
    currentStepLabelColor: 'green',
    labelAlign: "flex-start",
    // Customizable Area Start
    //Customizable Area End
  }

  
  constructor(props: Props) {
    super(props);
 
    this.receive = this.receive.bind(this);
   

    this.subScribedMessages = [

      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {

      // Customizable Area Start
      token: "",
      orderId:"0",
      date: "",
      price: 0,
      name: "",
      color: "",
      quantity: 0,
      status: "",
      position: 0,
      completed: false,
      delivered: "",
      loading: true,
      myId: 0,
      partner: "",
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
   
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Token", message);
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    
      // Customizable Area Start
      //Customizable Area End
    }
    else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      // Customizable Area Start
      //Customizable Area End
      this.setState({
        // myId: message.getData(getName(MessageEnum.MyID)),
        // orderId: message.getData(getName(MessageEnum.MyOrderId)),
        // date: message.getData(getName(MessageEnum.MyOrderDate)),
        // price: message.getData(getName(MessageEnum.MyOrderPrice)),
        // name: message.getData(getName(MessageEnum.MyOrderName)),
        // color: message.getData(getName(MessageEnum.MyOrderColor)),
        // quantity: message.getData(getName(MessageEnum.MyOrderQuantity)),
        // completed: message.getData(getName(MessageEnum.MyOrderComplete)),
        // delivered: message.getData(getName(MessageEnum.MyOrderDeliver)),
      })
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      //Customizable Area End
      runEngine.debugLog("API Message Recived", JSON.stringify(message));

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {

        if (apiRequestCallId === this.getOrderStatusCallId) {


          this.setState({ status: responseJson.data.attributes.status })
          this.setState({ partner: responseJson.data.attributes.delivery_service_provider })
          this.findCurrentPosition()
        }
      } else if (errorReponse || responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

  }


  async componentDidMount() {
    //Customizable Area Start
    //Customizable Area End
    setTimeout(() => { this.setState({ loading: false }) }, 2500)
    this.getOrderStatusUpdateWeb()
    super.componentDidMount();
  }
  findCurrentPosition = () => {
    for (let item = 0; item < this.labels.length; item++) {
      if (this.state.status === this.labels[item]) {
        this.setState({ position: item })
      }
    }
  }
  goBack() {
    this.props.navigation.goBack()
  }


  getOrderStatusUpdate = async () => {

    const token = await StorageProvider.get("TOKEN");

    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      token:token

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderStatusCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderStatusApiEndPoint + `/${this.state.myId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getOrderStatusUpdateWeb = async () => {

  
    const header = {
      "Content-Type": configJSON.ordersApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderStatusCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderStatusApiEndPoint + `/${sessionStorage.getItem('itemId')as string}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  //Customizable Area Start
  // Customizable Area End
}
