import React from "react";
// Customizable Area Start

import { Box, Button, Grid, InputBase, InputLabel, MenuItem, TextField, Typography } from "@material-ui/core";
import SellerBusinessDetailsController, {
    BusinessType,
    Country,
    Data,
    Document,
    Props,
    configJSON
} from "./SellerBusinessDetailsController.web";
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import {
    leftArrow, rightArrow, uploadImage, crossMark, companyPlaceholder,
    greenTick, pdfIcon, docIcon
} from "./assets";
// Customizable Area End


// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    headerLeftSection: {
        display: "flex",
        alignItems: "center",
    },
    step: {
        display: "flex",
        width: "32px",
        height: "32px",
        flexDirection: "column" as const,
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "200px"
    },
    activeStep: {
        backgroundColor: "#003C6E",
    },
    inactiveStep: {
        backgroundColor: "#64798B",
    },
    stepLabel: {
        color: "#003C6E",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        margin: "1em 1em"
    },
    inactiveStepLabel: {
        color: "#64798B",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        margin: "1em 1em"
    },
    headerRightSection: {
        padding: "0.25em 0em"
    },
    stepText: {
        color: "#FFF",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px"
    },
    header: {
        display: "flex",
        justifyContent: "space-between"
    },
    mainContainer: {
        padding: "40px 80px"
    },
    title: {
        color: "#101828",
        fontFamily: "Montserrat",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "38px"
    },
    subTitle: {
        color: "#475467",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px"
    },
    formsSection: {
        marginTop: "40px",
        paddingBottom: "80px"
    },
    uploadDocumentSection: {
        marginTop: "40px",
        paddingBottom: "80px",
        overflowY: 'auto' as const,
        flex: 1,
        paddingLeft: "10px"
    },
    sectionTitle: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '-0.12px',
    },
    sectionSubTitle: {
        color: '#777E90',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        marginBottom: "27px"
    },
    flexContainer: {
        display: "flex"
    },
    documentInnerSection: {
        display: "flex",
        flexDirection: "column" as const,
        marginRight: "2em"
    },
    documentTitle: {
        marginBottom: "1em",
        color: 'var(--neutral-05, #33383F)',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '-0.14px',
    },
    imagePreviewContainer: {
        position: 'relative' as const,
        width: '256px',
        height: '222px',
    },
    errorSpan: {
        color: 'red',
        fontSize: '14px',
        marginTop: '8px',
    },
    focusedTextField: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#003C6E",
        },
        marginTop: 0,
        marginBottom: "1em"
    },
    required: {
        color: "#FD3A3A",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.14px",
    },
    inputLabel: {
        color: "#33383F",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.14px",
    },
    placeholderStyle: {
        color: "#64748B",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    },
    footerContainer: {
        padding: "20px 106px",
        backgroundColor: "#F5E6DC"
    },
    footerInnerContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "32px",
        alignSelf: "stretch",
        margin: "1em 0em"
    },
    footerText: {
        color: "#000",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    },
    footerbackButton: {
        width: "168px",
        display: "flex",
        height: "40px",
        padding: "0px 20px",
        alignItems: "center",
        gap: "73px",
        borderRadius: "8px",
        background: "#FFF",
        color: "#242429",
        fontFamily: "Montserrat",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "12px",
        letterSpacing: "1.36px",
        textTransform: "capitalize" as const,
        justifyContent: "space-between",
        '&:hover': {
            background: "#FFF",
        },
        pointer: "cursor"
    },
    footerNextButton: {
        width: "168px",
        height: "40px",
        padding: "10px 16px",
        borderRadius: "8px",
        background: "#163B6A",
        color: "#FFF",
        fontFamily: "Montserrat",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "12px",
        letterSpacing: "1.36px",
        textTransform: "capitalize" as const,
        justifyContent: "space-between",
        '&:hover': {
            background: "#163B6A",
        },
        pointer: "cursor"
    },
    crossMark: {
        width: "16px",
        height: "16px",
        borderRadius: "100px"
    },
    crossMarkSection: {
        position: 'absolute' as const,
        top: 0,
        right: 0,
        cursor: 'pointer',
        padding: '8px',
        pointer: "cursor"
    },
    uploadImage: {
        width: "24px",
        height: "24px"
    },
    uploadButton: {
        display: "flex",
        padding: "8px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        border: "2px solid var(--neutral-03, #EFEFEF)",
        background: "var(--neutral-01, #FCFCFC)",
        boxShadow: "0px 12px 13px -6px rgba(0, 0, 0, 0.04)",
        color: "var(--neutral-07, #1A1D1F)",
        fontFamily: "Montserrat",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "-0.13px",
        width: "11em",
        margin: "4em 0em 0em 2em",
    },
    imagePreviewInnerContainer: {
        position: 'relative' as const,
        width: '100%',
        height: '100%',
        marginTop: "2em"
    },
    imagePreview: {
        width: '100%',
        height: '100%',
        objectFit: 'cover' as const,
        borderRadius: "8px"
    },
    errorRequired: {
        color: 'red',
        fontSize: '14px',
        position: 'absolute' as const,
        top: '130',
        right: '80',
    },
    uploadDocumentButton: {
        display: "flex",
        padding: "8px 16px",
        justifyContent: "center",
        alignItems: "center" as const,
        gap: "8px",
        borderRadius: "8px",
        border: "2px solid var(--neutral-03, #EFEFEF)",
        background: "var(--neutral-01, #FCFCFC)",
        boxShadow: "0px 12px 13px -6px rgba(0, 0, 0, 0.04)",
        pointer: "cursor"
    },
    uploadDocumentButtonText: {
        color: 'var(--neutral-07, #1A1D1F)',
        fontFamily: 'Montserrat',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '-0.13px',
    },
    documentsSideBar: {
        position: 'sticky' as const,
        top: 0,
        width: '270px',
        height: '100vh',
        overflowX: 'auto' as const,
        display: 'inline-flex' as const,
        padding: '24px',
        flexDirection: 'column' as const,
        justifyContent: 'flex-start',
        alignItems: 'flex-start' as const,
        gap: '16px',
        borderRadius: '16px',
        border: '1px solid var(--neutrals-7, #F4F5F6)',
        background: 'var(--neutrals-8, #FCFCFD)',
        zIndex: 100,
        marginTop: "1em"
    },
    documentsSideBarInnerSection: {
        display: "flex",
        flexDirection: "column" as const
    },
    eachStep: {
        display: "flex",
        alignItems: "center" as const,
        marginBottom: "16px"
    },
    uploadedStep: {
        display: 'flex',
        width: '24px',
        height: '24px',
        justifyContent: 'center',
        alignItems: 'center' as const,
        gap: '16px',
        flexShrink: 0,
        borderRadius: '100px',
        background: 'var(--primary-purple-500-main, #003C6E)',
    },
    uploadStep: {
        display: 'flex',
        width: '24px',
        height: '24px',
        justifyContent: 'center',
        alignItems: 'center' as const,
        gap: '16px',
        flexShrink: 0,
        borderRadius: '100px',
        background: '#DDD',
    },
    uploadedStepText: {
        color: '#FFF',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
    },
    uploadStepText: {
        color: '#777E90',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
    },
    sectionTitleUploaded: {
        color: '#003C6E',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
        margin: "0px 10px",
    },
    sectionTitleNotUploaded: {
        color: '#777E90',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        margin: "0px 10px",
    },
    uploadDocument: {
        position: 'relative' as const,
        background: "#F4F4F4",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column" as const,
        alignItems: "center" as const,
        width: "302px",
        height: "141px",
        borderRadius: "18px",
    },
    uploadDocumentsMainContainer: {
        display: "flex",
        marginTop: "1em",
        paddingLeft: "10px"
    },
    documentsDivider: {
        margin: "60px 0px",
        background: "#D9D9D9",
        width: '55.6em',
        height: "2px",
    },
    greenTick: {
        width: "24px",
        height: "24px"
    },
    uploadedDocumentName: {
        marginBottom: "1em",
        color: '#000',
        fontFamily: 'Montserrat',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '-0.13px',
        marginLeft: '1em'
    },
    uploadDocumentIcon: {
        marginBottom: "8px"
    }

});
// Customizable Area End

export class SellerBusinessDetails extends SellerBusinessDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    footerSection = () => {
        const { classes } = this.props;
        return (<Box className={classes.footerContainer}>
            <Box className={classes.footerInnerContainer}>
                <Typography className={classes.footerText}>
                    {configJSON.footerText}
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.footerbackButton}
                    startIcon={<img src={leftArrow} alt="leftArrow" />}
                    onClick={this.handleBack.bind(this)}
                    data-test-id="backButton"
                >
                    {configJSON.backButtonText}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.footerNextButton}
                    endIcon={<img src={rightArrow} alt="rightArrow" />}
                    onClick={this.handleNext.bind(this)}
                    data-test-id="nextButton"
                >
                    {configJSON.nextButtonText}
                </Button>
            </Box>
        </Box>)
    }

    businessDetailsFormSection = () => {
        const { classes } = this.props;
        return (
            <Box className={classes.formsSection} data-test-id="formsSection">
                <Grid container spacing={10}>
                    <Grid item xs={12} md={4}>
                        <Box>
                            <Box className={classes.imagePreviewContainer}>
                                {this.state.imagePreview ? (
                                    <Box className={classes.imagePreviewInnerContainer}>
                                        <img
                                            src={this.state.imagePreview}
                                            alt="Preview"
                                            className={classes.imagePreview}
                                        />
                                        <Box
                                            className={classes.crossMarkSection}
                                            onClick={this.removeImagePreview.bind(this)}
                                            data-test-id="crossMarkSection"
                                        >
                                            <img src={crossMark} alt="crossMark" className={classes.crossMark} />
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box className={classes.imagePreviewInnerContainer}>
                                        <img
                                            src={companyPlaceholder}
                                            alt="companyPlaceholder"
                                            className={classes.imagePreview}
                                        />
                                    </Box>
                                )}
                                <Box>
                                    <InputBase
                                        type="file"
                                        onChange={this.handleFileChange}
                                        style={{ display: 'none' }}
                                        id="upload-input"
                                        inputProps={{ accept: 'image/*' }}
                                        data-test-id="upload-input"
                                    />
                                    <InputLabel htmlFor="upload-input">
                                        <Box
                                            className={classes.uploadButton}
                                        >
                                            <img src={uploadImage} alt="uploadImage" className={classes.uploadImage} />{configJSON.uploadImage}
                                        </Box>
                                    </InputLabel>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Second Box */}
                    <Grid item xs={12} md={4}>
                        <Box>
                            <InputLabel className={classes.inputLabel}>
                                {configJSON.companyNameLabel}<sup className={classes.required}>{configJSON.required}</sup>
                            </InputLabel>
                            <TextField
                                data-test-id="companyNameInput"
                                fullWidth
                                variant="outlined"
                                placeholder={configJSON.companyNamePlaceholder}
                                className={classes.focusedTextField}
                                value={this.state.companyName}
                                onChange={(e) => this.updateTextFieldValues(configJSON.companyName, e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholderStyle,
                                    },
                                }}
                                required
                            />
                            <InputLabel className={classes.inputLabel}>
                                {configJSON.businessTypeLabel}<sup className={classes.required}>{configJSON.required}</sup>
                            </InputLabel>
                            <TextField
                                data-test-id="businessTypeInput"
                                fullWidth
                                variant="outlined"
                                select
                                placeholder={configJSON.businessTypePlaceholder}
                                className={classes.focusedTextField}
                                value={this.state.businessType}
                                onChange={(e) => this.updateTextFieldValues(configJSON.businessType, e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholderStyle,
                                    },
                                }}
                                SelectProps={{ native: false }}
                                defaultValue={configJSON.default}
                            >
                                <MenuItem value={configJSON.default} disabled>
                                    {configJSON.businessTypePlaceholder}
                                </MenuItem>
                                {configJSON.businessTypes.map((each: BusinessType) => (
                                    <MenuItem key={each.id} value={each.type}>
                                        {each.type}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <InputLabel className={classes.inputLabel}>
                                {configJSON.businessRegistrationNumberLabel}
                            </InputLabel>
                            <TextField
                                data-test-id="businessRegistrationNumber"
                                fullWidth
                                variant="outlined"
                                placeholder={configJSON.businessRegistrationNumberPlaceholder}
                                className={classes.focusedTextField}
                                value={this.state.businessRegistrationNumber}
                                onChange={(e) => this.updateTextFieldValues(configJSON.businessRegistrationNumber, e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholderStyle,
                                    },
                                }}
                            />
                            <InputLabel className={classes.inputLabel}>
                                {configJSON.phoneNumberLabel}
                            </InputLabel>
                            <TextField
                                fullWidth
                                data-test-id="phoneNumber"
                                variant="outlined"
                                placeholder={configJSON.phoneNumberPlaceholder}
                                className={classes.focusedTextField}
                                value={this.state.phoneNumber}
                                onChange={(e) => this.updateTextFieldValues(configJSON.phoneNumber, e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholderStyle,
                                    },
                                }}
                                type="number"
                            />
                        </Box>
                    </Grid>

                    {/* Third Box */}
                    <Grid item xs={12} md={4}>
                        <Box>
                            <InputLabel className={classes.inputLabel}>{configJSON.businessAddressLabel}</InputLabel>
                            <TextField
                                data-test-id="businessAddressStreet"
                                fullWidth
                                variant="outlined"
                                placeholder={configJSON.streetAddressPlaceholder}
                                className={classes.focusedTextField}
                                value={this.state.businessAddress}
                                onChange={(e) => this.updateTextFieldValues(configJSON.businessAddress, e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholderStyle,
                                    },
                                }}
                            />

                            <TextField
                                data-test-id="city"
                                fullWidth
                                variant="outlined"
                                placeholder={configJSON.cityPlaceholder}
                                className={classes.focusedTextField}
                                value={this.state.city}
                                onChange={(e) => this.updateTextFieldValues(configJSON.city, e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholderStyle,
                                    },
                                }}
                            />
                            <TextField
                                data-test-id="state"
                                fullWidth
                                variant="outlined"
                                placeholder={configJSON.stateProvincePlaceholder}
                                className={classes.focusedTextField}
                                value={this.state.stateProvince}
                                onChange={(e) => this.updateTextFieldValues(configJSON.stateProvince, e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholderStyle,
                                    },
                                }}
                            />
                            <TextField
                                data-test-id="postalCode"
                                fullWidth
                                variant="outlined"
                                placeholder={configJSON.postalCodePlaceholder}
                                className={classes.focusedTextField}
                                value={this.state.postalCode}
                                onChange={(e) => this.updateTextFieldValues(configJSON.postalCode, e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholderStyle,
                                    },
                                }}
                            />
                            <InputLabel className={classes.inputLabel}>{configJSON.countryLabel}</InputLabel>
                            <TextField
                                data-test-id="country"
                                fullWidth
                                variant="outlined"
                                select
                                className={classes.focusedTextField}
                                value={this.state.country}
                                onChange={(e) => this.updateTextFieldValues(configJSON.country, e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.placeholderStyle,
                                    },
                                }}
                                SelectProps={{ native: false }}
                                defaultValue={configJSON.default}
                            >
                                <MenuItem value={configJSON.default} disabled>
                                    {configJSON.selectCountryPlaceholder}
                                </MenuItem>
                                {configJSON.countries.map((eachObject: Country) => (
                                    <MenuItem key={eachObject.id} value={eachObject.country}>
                                        {eachObject.country}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                </Grid>
            </Box>)
    }

    uploadDocumentsSection = () => {
        const { classes } = this.props
        return (<Box className={classes.uploadDocumentsMainContainer} data-test-id="uploadDocumentsMainContainer">
            <Box className={classes.documentsSideBar}>
                <Box className={classes.documentsSideBarInnerSection}>
                    {configJSON.data.map((section: Data, index: number) => {
                        const filled = this.areAllDocumentsUploaded(section.id)
                        return (<Box className={classes.eachStep} key={section.id} >
                            <Typography className={filled ? classes.uploadedStep : classes.uploadStep} component="div">
                                <Typography className={filled ? classes.uploadedStepText : classes.uploadStepText} component="span">
                                    {index + 1}
                                </Typography>
                            </Typography>

                            <Typography className={filled ? classes.sectionTitleUploaded : classes.sectionTitleNotUploaded} component="span">
                                {section.sectionTitle}
                            </Typography>
                            {filled ? (
                                <Typography component="span">
                                    <img src={greenTick} alt="greenTick" />
                                </Typography>
                            ) : null}
                        </Box>)
                    })}

                </Box>
            </Box>
            <Box className={classes.uploadDocumentSection}>
                {configJSON.data.map((section: Data) => (
                    <React.Fragment key={section.id}>
                        <Box component="div" key={section.id}>
                            <Typography className={classes.sectionTitle}>
                                {section.sectionTitle}
                            </Typography>
                            <Typography className={classes.sectionSubTitle}>
                                {section.sectionSubTitle}
                            </Typography>
                            <Box component="div" className={classes.flexContainer}>
                                {section.documents.map((document: Document) => (
                                    <Box component="div" key={document.docId} className={classes.documentInnerSection}>
                                        <Typography component="span" className={classes.documentTitle}>{document.title}</Typography>
                                        <Box>
                                            <Box className={classes.uploadDocument}>
                                                <Box className={classes.flexContainer}>
                                                    {this.state.documents[document.docId] && (
                                                        <>
                                                            {this.state.documents[document.docId]?.file?.name.toLowerCase().endsWith('.pdf') && (
                                                                <img src={pdfIcon} alt="pdfIcon" className={classes.uploadDocumentIcon} />
                                                            )}
                                                            {this.state.documents[document.docId]?.file?.name.toLowerCase().endsWith('.doc') && (
                                                                <img src={docIcon} alt="docIcon" className={classes.uploadDocumentIcon} />
                                                            )}
                                                            <Typography data-test-id="document-preview" component="span" className={classes.uploadedDocumentName}>{this.state.documents[document.docId]?.file?.name}</Typography>
                                                        </>
                                                    )}
                                                </Box>
                                                <Box>
                                                    <InputBase
                                                        type="file"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleDocumentChange(document.docId, e)}
                                                        style={{ display: 'none' }}
                                                        id={`document-upload-input-${document.docId}`}
                                                        inputProps={{ accept: '.pdf,.doc,.docx' }}
                                                        data-test-id={`document-upload-input-${document.docId}`}
                                                    />
                                                    <InputLabel htmlFor={`document-upload-input-${document.docId}`}>
                                                        <Box className={classes.uploadDocumentButton}>
                                                            <img src={uploadImage} alt="uploadDocumentIcon" className={classes.uploadImage} />
                                                            <Typography component="span" className={classes.uploadDocumentButtonText}>{configJSON.uploadDocument}</Typography>
                                                        </Box>
                                                    </InputLabel>
                                                    {this.state.documents[document.docId] && (
                                                        <Box
                                                            data-test-id={`remove-document-${document.docId}`}
                                                            className={classes.crossMarkSection}
                                                            onClick={() => this.removeDocumentPreview(document.docId)}>
                                                            <img src={crossMark} alt="crossMark" className={classes.crossMark} />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>

                                    </Box>
                                ))}
                            </Box>

                        </Box>
                        <div className={classes.documentsDivider}></div>
                    </React.Fragment>
                ))}
            </Box>
        </Box>)
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
  
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Box className={classes.mainContainer} data-test-id="mainContainer">
                    <Box className={classes.header}>
                        <Box className={classes.headerRightSection}>
                            <Typography className={classes.title}>{configJSON.businessDetails}</Typography>
                            <Typography className={classes.subTitle}>{configJSON.enterTheDetails}</Typography>
                        </Box>
                        <Box className={classes.headerLeftSection}>
                            <Box
                                className={`${classes.step} ${this.state.step === 1 ? classes.activeStep : classes.inactiveStep}`}
                            >
                                <span className={classes.stepText}>1</span>
                            </Box>
                            <Typography
                                className={`${this.state.step === 1 ? classes.stepLabel : classes.inactiveStepLabel}`}
                            >
                                {configJSON.businessDetails}
                            </Typography>

                            <Box
                                className={`${classes.step} ${this.state.step === 2 ? classes.activeStep : classes.inactiveStep}`}
                            >
                                <span className={classes.stepText}>2</span>
                            </Box>
                            <Typography
                                className={`${this.state.step === 2 ? classes.stepLabel : classes.inactiveStepLabel}`}
                            >
                                {configJSON.uploadDocuments}
                            </Typography>
                        </Box>

                    </Box>
                    {this.state.errorRequiredFields && (
                        <Typography component="span" className={classes.errorRequired} data-test-id="errorMessage">
                            {this.state.errorRequiredFields}
                        </Typography>
                    )}
                    {this.state.step === 1 ?
                        this.businessDetailsFormSection() :
                        this.uploadDocumentsSection()
                    }
                </Box>
                {this.footerSection()}
            </ThemeProvider>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(SellerBusinessDetails)
// Customizable Area End