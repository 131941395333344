import React from "react";
// Customizable Area Start
import NavBarWeb from "../../../components/src/NavBar.web";
import {
  Box,
  Button,
  Snackbar,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import OtpInput from "react-otp-input";
import { back } from "./assets"
import Alert from '@material-ui/lab/Alert';
export const configJSON = require("./config");
// Customizable Area End

import OTPInputAuthController, { Props } from "./OTPInputAuthController";

class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderOTPScreen = (style: Record<string, string>) => {
    return (
      <>
        <Box className={style.loginOTPContainer}>
          <Box className={style.backImageContainer} onClick={this.handleGoBack} data-test-id="goBackTestID">
            <img src={back} alt="image" className={style.backImage} />
          </Box>
          <Box className={style.otpMainContainer}>
            <Box className={style.otpCheckEmailBox}>
              <Typography className={style.checkYourEmailText}>
                {configJSON.check}
              </Typography>
              <Typography className={style.verificationLinkText}>
                {configJSON.verification} {this.state.userEmailID}
              </Typography>
            </Box>
            {this.state.otpErrors.isOtpErros &&
              <Box className={style.rhodiumErrorContainer}>
                <Typography className={style.rhodiumErrorContainerText}>
                  {this.state.otpErrors.otpErrorMessage}
                </Typography>
              </Box>
            }
            <Box className={style.otpBoxContainer}>
              <Box className={style.otpInputBoxContainer}>
                <OtpInput
                  data-test-id="otpInputTestID"
                  value={this.state.otpValues}
                  onChange={this.handleOTPChange}
                  numInputs={4}
                  inputStyle={style.inputStyle}
                  containerStyle={{ gap: "12px" }}
                  renderInput={(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>) => <input {...props} />}
                />
              </Box>

              <Box>
                <Button
                  data-test-id="verifyEmailButtonTestID"
                  onClick={this.handleVerifyEmail}
                  className={style.verifyEmailButton}>
                  {configJSON.Manually}
                </Button>
              </Box>

              <Box>
                <Typography className={style.blueTimerText}>
                  {configJSON.timeRemaining} {this.formatTime(this.state.seconds)}
                </Typography>
              </Box>
              <Box>
                <Typography className={style.otpDoNotText}>
                  {configJSON.emailReceive}{" "}
                  <span data-test-id="resendButtonTestID" className={style.blueotpDoNotText} onClick={this.resetTimer}>Click to resend</span>
                </Typography>
              </Box>
              <Box>
                <Typography className={style.backToText}>
                  {configJSON.backTo} &nbsp;<span data-test-id="loginButtonTestID" className={style.blueotpDoNotText}>{configJSON.logIn}</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  renderOtpCarosuelScreen = (style: Record<string, string>) => {
    return (
      <>
        {/* <Slider /> */}
      </>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const style = this.props.classes;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={style.rhodiumLoginContainer}>
        <NavBarWeb loginLoc={"signup"} />
        <Box className={style.mainContainer}>
          <Box className={style.otpContainer}>
            {this.renderOTPScreen(style)}
          </Box>

          <Box className={style.imageContainer}>
            {this.renderOtpCarosuelScreen(style)}
          </Box>
        </Box>
        {this.state.hasSuccessError.isOpen && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.hasSuccessError.isOpen}
            onClose={this.handleCloseSnack}
            autoHideDuration={6000}
          >
            <Alert data-test-id="snackBarCloseTestId" onClose={this.handleCloseSnack} severity={this.state.hasSuccessError.isSeverity}>
              {this.state.hasSuccessError.isMessage}
            </Alert>
          </Snackbar>
        )}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = createStyles({
  rhodiumLoginContainer: {
    width: "100%",
  },
  mainContainer: {
    display: "flex",
  },
  otpContainer: {
    backgroundColor: "#FDFBFA",
    width: "50%",
    display: "flex",
    justifyContent: "center",
  },
  loginOTPContainer: {
    width: "375px",
    height: "max-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "80px",
    marginTop: "16px"
  },
  imageContainer: {
    width: "50%",
  },
  checkYourEmailText: {
    color: "#0F1E2A",
    fontFamily: "Montserrat",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    alignSelf: "stretch",
  },
  verificationLinkText: {
    color: "#64798B",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    alignSelf: "stretch",
  },
  inputStyle: {
    width: " 80px !important",
    height: " 80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 8px",
    borderRadius: "8px",
    border: "2px solid #163B6A",
    background: "#FDFBFA",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    color: "#163B6A",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: '48px',
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "60px",
    letterSpacing: "-0.96px",
    "&:focus": {
      outline: "none",
      boxShadow: "0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    }
  },
  verifyEmailButton: {
    width: "375px",
    display: "flex",
    height: "56px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: "#003C6E",
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    "&:hover": {
      background: "#003C6E"
    }
  },
  footerTextContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0px",
    justifyContent: "space-between",
    width: "350px",
    marginTop: "1rem",
    height: "100px",
  },
  blueTimerText: {
    color: "#003C6E",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  },
  imageContainerZ: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  image: {
    display: "flex",
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  otpMainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
  },
  backImageContainer: {
    padding: "8px",
    borderRadius: "40px",
    background: "#F5E6DC",
  },
  backImage: {
    width: "24px",
    height: "24px",
  },
  otpCheckEmailBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
  },
  otpBoxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "26px",
  },
  otpInputBoxContainer: {
    display: "flex",
    justifyContent: "center",
    width: "375px",
  },
  otpDoNotText: {
    color: "#334655",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  blueotpDoNotText: {
    color: "#003C6E",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer"
  },
  backToText: {
    color: "#64798B",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  rhodiumErrorContainer: {
    display: "flex",
    height: "42px",
    padding: "12px 31px 12px 16px",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "4px",
    borderLeft: "4px solid #DC2626",
    background: "#FEE2E2",
  },
  rhodiumErrorContainerText: {
    color: "#DC2626",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
  }
});
export default withStyles(styles)(OTPInputAuth);
export { OTPInputAuth }
// Customizable Area End
