import React from "react";
// Customizable Area Start
import NavBarWeb from "../../../components/src/NavBar.web";
import clsx from 'clsx';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Seller, Buyer, Google, BuyerDark, SellerDark } from "./assets"
export const configJSON = require("./config");
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCreateAccountValidation = (style: Record<string, string>) => {
    return (
      <Box className={this.state.blankEmailError || this.state.blankNameError || this.state.emailError || this.state.checkBoxError ? style.rhodiumErrorContainer : style.rhodiumErrorContainerHide}>
        <Typography className={this.state.blankNameError ? style.rhodiumErrorContainerText : style.rhodiumErrorContainerTextHide}>
          {configJSON.nameblankError}
        </Typography>
        <Typography className={this.state.blankEmailError ? style.rhodiumErrorContainerText : style.rhodiumErrorContainerTextHide}>
          {configJSON.emailblankError}
        </Typography>
        <Typography className={this.state.emailError ? style.rhodiumErrorContainerText : style.rhodiumErrorContainerTextHide}>
          {configJSON.emailValidation}
        </Typography>
        <Typography className={this.state.checkBoxError ? style.rhodiumErrorContainerText : style.rhodiumErrorContainerTextHide}>
          {configJSON.checkBoxValidation}
        </Typography>
      </Box>
    )
  }

  renderCreateAccountScreen = (style: Record<string, string>) => {
    return (
      <Box className={style.rhodiumCreateAnAccount}>
        <Box>
          <Typography className={style.createAnAccountHeading}>
            Create an account
          </Typography>
          <Typography className={style.createAnAccountSubHeading}>
            Welcome to Peza Ezone
          </Typography>
        </Box>
        <Box>
          <Typography className={style.rhodiumJoinUsText}>
            Join as...
          </Typography>
          <Box className={style.rhodiumSellerAndBuyerContainer}>
            <Button data-test-id="sellerTestID" className={this.state.buttonSwitchSellerBuyer ? style.rhodiumSellerButton : style.rhodiumBuyerButton} onClick={this.sellerToBuyer}>
              <img src={this.state.buttonSwitchSellerBuyer ? Seller : SellerDark} alt='Seller' className={style.rhodiumBuyerSellerIcon} />
              <Typography className={this.state.buttonSwitchSellerBuyer ? style.rhodiumSellerButtonText : style.rhodiumBuyerButtonText}>Seller</Typography>
            </Button>
            <Button data-test-id="buyerTestID" className={this.state.buttonSwitchSellerBuyer ? style.rhodiumBuyerButton : style.rhodiumSellerButton} onClick={this.buyerToSelller}>
              <img src={this.state.buttonSwitchSellerBuyer ? Buyer : BuyerDark} alt='Buyer' className={style.rhodiumBuyerSellerIcon} />
              <Typography className={this.state.buttonSwitchSellerBuyer ? style.rhodiumBuyerButtonText : style.rhodiumSellerButtonText}>Buyer</Typography>
            </Button>
          </Box>
        </Box>
        {this.renderCreateAccountValidation(style)}
        <Box>
          <form
            data-test-id="formSubmitTestID"
            autoComplete="off" className={style.rhodiumLoginFormContainer} onSubmit={this.handleSignUp}
          >
            <Box className={style.rhodiumFormFieldContainer}>
              <Typography className={style.rhodiumFormFieldLabel}>Full Name</Typography>
              <TextField
                data-test-id="nameInputTestID"
                error={this.state.blankNameError}
                placeholder="Your Full Name"
                className={style.rhodiumFormInputBox}
                id="outlined-basic"
                variant="outlined"
                value={this.state.userName}
                onChange={this.handleUserNameInput}
              />
            </Box>
            <Box className={style.rhodiumFormFieldContainer}>
              <Typography className={style.rhodiumFormFieldLabel}>Email</Typography>
              <TextField
                data-test-id="emailInputTestID"
                error={this.state.emailError || this.state.blankEmailError}
                placeholder="Your Email"
                className={style.rhodiumFormInputBox}
                id="outlined-basic"
                variant="outlined"
                value={this.state.userEmailID}
                onChange={this.handleUserEmailInput}
              />
            </Box>
            <Box className={style.rhodiumFormCheckBoxAndContinue}>
              <Box>
                <Box>
                  <FormControlLabel
                    data-test-id="formControlId"
                    control={
                      <Checkbox
                        onChange={this.handleTAndCCheckBox}
                        data-test-id="tandcInputTestID"
                        className={style.root}
                        disableRipple
                        color="default"
                        checked={this.state.buttonDisable}
                        checkedIcon={<span className={clsx(style.icon, style.checkedIcon)} />}
                        icon={<span className={style.icon} />}
                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                      />
                    }
                    label={<Typography className={style.rhodiumFormCheckBoxText}>I agree with <span className={style.rhodiumFormCheckBoxTextSpan}>Privacy Policy and Terms and Conditions</span></Typography>}
                  />
                </Box>
                <Button className={style.rhodiumFormContinueButton} type="submit">Continue</Button>
              </Box>
            </Box>
            <Box className={style.rhodiumFormSignUpWithContainer}>
              <Typography className={style.rhodiumFormFieldLabel}>Or Sign up with...</Typography>
              <Button className={style.rhodiumFormGoogleButton}>
                <img src={Google} alt='Google' className={style.rhodiumBuyerSellerIcon} />
                <Typography className={style.rhodiumFormGoogleButtonText}>Google</Typography>
              </Button>
            </Box>
          </form>
          <Box className={style.rhodiumAlreadyContainer}>
            <Typography className={style.rhodiumAlreadyText}>Already have an account?</Typography>
            <Typography className={style.rhodiumAlreadyLoginText}>Log in</Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  renderCreateAccountCarosuelScreen = (style: Record<string, string>) => {
    return (
      <>
      </>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const style = this.props.classes;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={style.rhodiumLoginContainer}>
        <NavBarWeb loginLoc={"signup"} />
        <Box className={style.createAccountMainContainer}>
          <Box className={style.createAccountMainBox}>
            {this.renderCreateAccountScreen(style)}
          </Box>
          <Box className={style.createAccountImageBox}>
            {this.renderCreateAccountCarosuelScreen(style)}
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = createStyles({
  rhodiumLoginContainer: {
    width: "100%",
  },
  createAccountMainContainer: {
    width: "100%",
    display: "flex",
  },
  createAccountMainBox: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    height: "820px",
    paddingTop: "64px",
    alignItems: "flex-start",
    gap: "8px",
    background: "#FCF7F3"
  },
  rhodiumCreateAnAccount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "32px",
  },
  createAnAccountHeading: {
    alignSelf: "stretch",
    color: "#101828",
    fontFamily: "Montserrat",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "38px",
    marginBottom: "8px",
  },
  createAnAccountSubHeading: {
    alignSelf: "stretch",
    color: "#475467",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  rhodiumJoinUsText: {
    color: "#33383F",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.14px",
    marginBottom: "4px",
  },
  rhodiumSellerAndBuyerContainer: {
    width: "375px",
    display: "flex",
    height: "56px",
    padding: "4px",
    alignItems: "flex-start",
    alignSelf: "stretch",
    borderRadius: "12px",
    background: "#F5E6DC",
  },
  rhodiumSellerButton: {
    width: "50%",
    display: "flex",
    height: "100%",
    padding: "6px 12px",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "8px",
    cursor: "pointer",
  },
  rhodiumBuyerSellerIcon: {
    width: "24px",
    height: "24px",
    marginRight: "8px",
  },
  rhodiumSellerButtonText: {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
  },
  rhodiumBuyerButton: {
    width: "50%",
    display: "flex",
    height: "100%",
    padding: "6px 12px",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "8px",
    cursor: "pointer",
    background: "#003C6E",
    alignSelf: "stretch",
    "&:hover": {
      backgroundColor: "#003C6E",
    },
  },
  rhodiumBuyerButtonText: {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
  },
  rhodiumLoginFormContainer: {
    width: "375px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch"
  },
  rhodiumFormFieldContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    width: "100%",
  },
  rhodiumFormFieldLabel: {
    color: "#33383F",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.14px",
  },
  rhodiumFormInputBox: {
    width: "100%",
    background: "transparent",
    border: "none",
    color: "#64748B",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    "& .MuiOutlinedInput-input": {
      padding: "16px 20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      border: "none"
    },
    "& label.Mui-focused": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
      },
      "&:hover fieldset": {
        // borderColor: "#CBD5E1",
        border: "1px solid #CBD5E1",
      },
      "&.Mui-focused fieldset": {
        // borderColor: "#CBD5E1",
        border: "1px solid #CBD5E1",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontWeight: 700,
      fontSize: "14px",
    },
  },
  rhodiumFormCheckBoxAndContinue: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "24px",
    width: "100%",
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 6,
    width: 20,
    height: 20,
    marginLeft: "2px",
    boxShadow: 'inset 0 0 0 1px #64748B, inset 0 -1px 0 #64748B',
    backgroundColor: 'transparent',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: 'transparent',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  rhodiumFormCheckBoxText: {
    color: "#0F172A",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    whiteSpace: "nowrap"
  },
  rhodiumFormCheckBoxTextSpan: {
    color: "#003C6E",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    textDecorationLine: "underline",
  },
  rhodiumFormContinueButton: {
    marginTop: "24px",
    display: "flex",
    height: "56px",
    width: "375px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: "#003C6E",
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: "#003C6E",
    },
  },
  rhodiumFormSignUpWithContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
    alignSelf: "stretch"
  },
  rhodiumFormGoogleButton: {
    display: "flex",
    width: "100%",
    height: "56px",
    padding: "12px 20px",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "12px",
    border: "2px solid #EED6C4",
    background: "#FDFBFA",
    "&:hover": {
      backgroundColor: "#FDFBFA",
    },
  },
  rhodiumFormGoogleButtonText: {
    color: "#1A1D1F",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "-0.15px",
  },
  rhodiumAlreadyContainer: {
    display: "flex",
    width: "375px",
    alignItems: "center",
    gap: "8px",
    marginTop: "32px",
  },
  rhodiumAlreadyText: {
    color: "#0F172A",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  rhodiumAlreadyLoginText: {
    color: "#163B6B",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
  },
  createAccountImageBox: {
    width: "50%",
  },
  rhodiumErrorContainer: {
    display: "block",
    height: "fit-content",
    padding: "12px 31px 12px 16px",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "4px",
    borderLeft: "4px solid #DC2626",
    background: "#FEE2E2",
    gap: "10px",
  },
  rhodiumErrorContainerText: {
    color: "#DC2626",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    display: "block",
  },
  rhodiumErrorContainerTextHide: {
    display: "none",
  },
  rhodiumErrorContainerHide: {
    display: "none",
  },
});
export default withStyles(styles)(EmailAccountRegistration);
export { EmailAccountRegistration }
// Customizable Area End
