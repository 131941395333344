// Customizable Area Start
//@ts-nocheck
import React from "react";
// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// let artBoardHeightOrg = 667;
// let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import WishlistController, {
  Props,
} from "./WishlistController";
import { scaledSize } from "../../../framework/src/Utilities";
import SnackBarAlert from "../../../components/src/SnackBarAlert.web"
import { BackArrowIcon, CrossIcon, DeleteIcon, Placeholder } from "./assets";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Box,
  Button,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import FooterWeb from "../../../components/src/Footer.web";
import NavBarWeb from "../../../components/src/NavBar.web";

class Wishlistweb extends WishlistController {
  constructor(props: Props) {
    super(props);

  }

  renderWishListTable = (style: Record<string, string>) => {
    return (
      <>
        <TableContainer component={Paper}>
          <Table className={style.table} aria-label="simple table">
            <TableHead className={style.tableHead}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"> <Typography className={style.wishListTableHeadText}>Product title</Typography></TableCell>
                <TableCell align="left"> <Typography className={style.wishListTableHeadText}>Price</Typography></TableCell>
                <TableCell align="left"> <Typography className={style.wishListTableHeadText}>Status</Typography></TableCell>
                <TableCell align="left"> <Typography className={style.wishListTableHeadText}>Action</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={style.tableBody}>
              {this.state.wishlistData.map((wishlistItem: WishlistItems, index: number) => {
                return (
                  <TableRow  >
                    <TableCell align="left">
                      <img data-test-id={`crossButtonTestID_${index}`} onClick={() => this.crossButton(wishlistItem.id)} src={CrossIcon} alt="crossIcon" className={style.crossIcon} />
                    </TableCell>
                    <TableCell align="left"><img src={Placeholder} alt="Placeholder" className={style.Placeholder} /></TableCell>
                    <TableCell align="left"><Typography className={style.wishListProductText}>{wishlistItem.attributes.bx_block_products_product.data.attributes.name}</Typography></TableCell>
                    <TableCell align="left"><Typography className={style.wishListPriceText}>₱{wishlistItem.attributes.bx_block_products_product.data.attributes.price}</Typography></TableCell>
                    <TableCell align="left"><Typography className={wishlistItem.attributes.bx_block_products_product.data.attributes.status !== "Out Of Stock" ? style.rhodiumWishListInStockText : style.rhodiumWishListOutStockText}>{wishlistItem.attributes.bx_block_products_product.data.attributes.status}</Typography></TableCell>
                    <TableCell align="left"><Button data-test-id={`addtocartButtonTestID_${index}`} onClick={() => this.addToCart(wishlistItem.attributes.bx_block_products_product.data.attributes.id)} className={wishlistItem.attributes.bx_block_products_product.data.attributes.status !== "Out Of Stock" ? style.wishListDeleteButton : style.wishListDeleteButtonChangeOpacity}><Typography className={style.wishListDeleteButtonText}>Add to cart</Typography><img src={DeleteIcon} alt="deleteIcon" className={style.deleteIcon} /></Button></TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
  renderWishList = (style: Record<string, string>) => {
    return (
      <>
        <Box className={style.rhodiumWishListContainer}>
          <Box className={style.rhodiumWishListTextContainer}>
            <img src={BackArrowIcon} alt="backArrowIcon" className={style.backArrowIcon} />
            <Typography className={style.rhodiumWishListText}>Your Wishlist ❤️</Typography>
          </Box>
          <Box className={style.rhodiumWishListTableContainer}>
            {this.renderWishListTable(style)}
          </Box>
        </Box>
      </>
    );
  }

  render() {
    const style = this.props.classes;
    // Merge Engine - render - Start
    return (
      <>
        <NavBarWeb loginLoc={""} />
        <Box className={style.rhodiumWishListMainContainer}>
          {this.renderWishList(style)}
        </Box>
        <FooterWeb />
        <SnackBarAlert snackBarProps={this.state.hasSuccessErrorForWishlist} handleCloseSnack={this.handleCloseSnack} />
      </>
    );
    // Merge Engine - render - End
  }
}

const styles = createStyles({
  rhodiumWishListMainContainer: {
    display: "flex",
    padding: "40px 80px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    background: "#FCF7F3",
  },
  rhodiumWishListContainer: {
    display: "flex",
    padding: "64px 96px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "24px",
    background: "#FFF",
    boxShadow: "0px 64px 64px -48px rgba(15, 15, 15, 0.10)",
  },
  rhodiumWishListTextContainer: {
    display: "flex",
    paddingBottom: "0px",
    alignItems: "center",
    gap: "24px",
    alignSelf: "stretch",
  },
  backArrowIcon: {
    width: "32px",
    height: "32px",
  },
  rhodiumWishListText: {
    color: "#23262F",
    fontFamily: "Montserrat",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.32px",
  },
  rhodiumWishListTableContainer: {
    width: "100%",
  },
  wishListTableHeadText: {
    color: "#23262F",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  wishListProductText: {
    color: "#23262F",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  wishListPriceText: {
    color: "#FF253F",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  rhodiumWishListInStockText: {
    color: "#45B26B",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  rhodiumWishListOutStockText: {
    color: "#EF466F",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  wishListDeleteButton: {
    display: "flex",
    padding: "12px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    border: "2px solid #B5B5B5",
  },
  wishListDeleteButtonText: {
    color: "#23262F",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    marginRight: "12px"
  },
  deleteIcon: {
    width: "16px",
    height: "16px",
  },
  wishListDeleteButtonChangeOpacity: {
    display: "flex",
    padding: "12px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    border: "2px solid #B5B5B5",
    opacity: 0.6
  },
  tableHead: {
    paddingBottom: "32px",
  },
  tableBody: {
    padding: "32px 0",
  },
  crossIcon: {
    cursor: "pointer"
  }
});
export default withStyles(styles)(Wishlistweb);
export { Wishlistweb }
// Customizable Area End
