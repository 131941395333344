import React, { Component } from 'react';
// Customizable Area Start
import { Box, Button, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";

// Customizable Area End

export interface Props {
  // Customizable Area Start
  loginLoc: string;
  classes: Record<string, string>;
  // Customizable Area End
}

class NavBar extends Component<
  Props
> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderLandingLoginButtonScreens = (style: Record<string, string>) => {
    return (
      <>
        <Button className={style.rhodiumLoginButton} >
          <Box className={style.rhodiumLoginCircle}>
            <img src={require("./profile_circled.svg")} alt='profile_circled' className={style.rhodiumNavbarDropsDown} />
          </Box>
          <Typography className={style.rhodiumLoginText}>Login</Typography>
        </Button>
      </>
    )
  }
  renderLandingSignupButtonScreens = (style: Record<string, string>) => {
    return (
      <>
        <Button className={style.rhodiumCreateAccountButton}>Create account</Button>
      </>
    )
  }
  // Customizable Area End

  render() {
    const style = this.props.classes;
    return (
      // Customizable Area Start
      <>
        <Box className={style.rhodiumNavbarContainer}>
          <Box className={style.rhodiumLogoContainer}>
            <img src={require("./logo.png")} alt='logo' className={style.rhodiumNavbarLogo} />
            <Box className={style.rhodiumNavbarDivider} ></Box>
            <Box className={style.rhodiumNavbarProductsContainer}>
              <img src={require("./brand-safari.svg")} alt='brand' className={style.rhodiumNavbarHomeLogo} />
              <Box className={style.rhodiumNavbarProductsInnerContainer}>
                <Typography className={style.rhodiumNavbarHomeText}>All Categories</Typography>
                <img src={require("./DownArrow.svg")} alt='Line' className={style.rhodiumNavbarDropsDown} />
              </Box>
            </Box>
          </Box>
          <Box className={style.rhodiumLoginContainer}>
            {
              (this.props.loginLoc === "signup" && this.renderLandingLoginButtonScreens(style)) ||
              (this.props.loginLoc === "login" && this.renderLandingSignupButtonScreens(style))
            }
            {this.props.loginLoc === "landing" && (
              <>
                {this.renderLandingLoginButtonScreens(style)}
                {this.renderLandingSignupButtonScreens(style)}
              </>
            )}
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = () =>
  createStyles({
    rhodiumNavbarContainer: {
      padding: "16px 80px",
      display: "flex",
        // width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-start",
      borderBottom: "1px solid #CBD7E1",
      background: "#FCF7F3",
    },
    rhodiumLogoContainer: {
      display: "flex",
      alignItems: "center",
    },
    rhodiumNavbarLogo: {
      width: "97.427px",
      height: "48px",
      cursor: "pointer",
    },
    rhodiumNavbarDivider: {
      width: "2px",
      height: "24px",
      margin: "0 40px",
      opacity: "0.2",
      background: "#0060B1",
    },
    rhodiumNavbarHomeLogo: {
      width: "24px",
      height: "24px",
    },
    rhodiumNavbarHomeText: {
      color: "#003C6E",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",

    },
    rhodiumNavbarProductsContainer: {
      display: "flex",
      padding: "12px 16px",
      alignItems: "center",
      gap: "12px",
      borderRadius: "12px",
      cursor: "pointer",
      background: "#FCF7F3",
    },
    rhodiumNavbarProductsInnerContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    rhodiumNavbarDropsDown: {
      width: "24px",
      height: "24px",
    },
    rhodiumLoginContainer: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    rhodiumLoginButton: {
      display: "flex",
      padding: "4px 16px 4px 4px",
      alignItems: "center",
      borderRadius: "90px",
      border: "2px solid #E6E8EC",
    },
    rhodiumLoginCircle: {
      display: "flex",
      width: "32px",
      height: "32px",
      padding: "4px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "48px",
      background: "#003C6E",
      marginRight: "12px",
    },
    rhodiumLoginText: {
      color: "#003C6E",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "16px",
    },
    rhodiumCreateAccountButton: {
      display: "flex",
      height: "40px",
      padding: "0px 16px",
      alignItems: "center",
      gap: "12px",
      borderRadius: "92px",
      background: "#003C6E",
      color: "#CDE8FE",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "16px",
      "&:hover": {
        backgroundColor: "#003C6E",
      },
    },
    rhodiumHideAndShow: {
      display: "none",
    }
  });
export default withStyles(styles)(NavBar);
export { NavBar }
// Customizable Area End
