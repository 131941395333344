Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account/accounts/sms_confirmation?pin=";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

exports.submitButtonColor = "#6200EE";
// Customizable Area Start
exports.postOtpAPIEndPoint = "accounts/email_otp_confirmation";
exports.resendOtpAPIEndPoint = "accounts/resend_otp";
exports.check = "Check your Email";
exports.verification = "We sent a verification link to ";
exports.Manually = "Verify Email";
exports.timeRemaining = "Time Remaining";
exports.emailReceive = "Didn’t receive the email?";
exports.backTo = "Back to";
exports.logIn = "Log In";
// Customizable Area End
