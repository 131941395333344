export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Seller = require("../assets/Seller_Icon.svg");
export const Buyer = require("../assets/Buyer_Icon.svg");
export const SellerDark = require("../assets/Seller_Dark_Icon.svg");
export const BuyerDark = require("../assets/Buyer_Dark_Icon.svg");
export const Google = require("../assets/Google.svg");
export const backIcon = require("../assets/back-icon.svg");
export const leftArrow = require("../assets/leftArrow.png");
export const rightArrow = require("../assets/rightArrow.png");
export const uploadImage = require("../assets/uploadImage.png");
export const crossMark = require("../assets/crossMark.png");
export const companyPlaceholder = require("../assets/companyPlaceholder.png");
export const pdfIcon = require("../assets/pdfIcon.png");
export const docIcon = require("../assets/docIcon.png");
export const greenTick = require("../assets/greenTick.png");
