// Customizable Area Start

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";

export type BusinessType = {
    id: number;
    type: string;
  };

  export  type Country = {
    id: number;
    country: string;
  };
  
  export type Document = {
    docId: number;
    title: string;
  };
  
  export type Data = {
    id: number;
    sectionTitle: string;
    sectionSubTitle: string;
    documents: Document[];
  };
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    step: number,
    imagePreview: null | string,
    errorRequiredFields: string,
    UploadedImageToSend: File | null,
    uploadedFileName: File | null
    companyName: string;
    businessType: string;
    businessRegistrationNumber: string;
    phoneNumber: number | null;
    businessAddress: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    country: string;
    uploadedDocument: null | File;
    uploadedDocumentName: string;
    documents: Record<number, { file: File | null; uploaded: boolean }>;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SellerBusinessDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            step: 1,
            imagePreview: null,
            errorRequiredFields: "",
            UploadedImageToSend: null,
            companyName: '',
            businessType: "default",
            businessRegistrationNumber: "",
            phoneNumber: null,
            businessAddress: "",
            city: "",
            stateProvince: "",
            postalCode: "",
            country: "default",
            uploadedFileName: null,
            uploadedDocument: null,
            uploadedDocumentName: "",
            documents: {},
            // Customizable Area End
        };
    }

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    handleFileChange = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
        const file = fileEvent.target.files?.[0];
        if (file instanceof Blob) {
            const image = URL.createObjectURL(file);
            this.setState({
                imagePreview: image,
                UploadedImageToSend: file,
            });
        }
    };

    removeImagePreview() {
        this.setState({
            imagePreview: null,
            UploadedImageToSend: null,
        });
    }

    handleNext = () => {
        const number = this.state.step
        const { companyName, businessType } = this.state;
        if (this.state.step === 1 && (!companyName.trim() || businessType === "default")) {
            this.setState({
                errorRequiredFields: "Please fill the required * fields to Continue.",
            });
        } else {
            this.setState({ errorRequiredFields: "" });
            if (number === 1) {
                this.setState({ step: number + 1 });
            }
        }

    }

    handleBack = () => {
        this.setState((prevState) => {
            if (prevState.step > 1) {
                return { step: prevState.step - 1 };
            }
            return null; 
        });
    }
    updateTextFieldValues = (key: keyof S, value: any) => {
        this.setState({ [key]: value } as Pick<S, keyof S>);
    };

    handleDocumentChange = (docId: number, e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.[0]) {
            this.updateDocumentPreview(e.target.files[0], docId);
        }
    };

    updateDocumentPreview = (file: File, docId: number) => {
        const updatedDocuments = {
            ...this.state.documents,
            [docId]: {
                file: file,
                uploaded: true,
            },
        };

        this.setState({
            uploadedDocumentName: file.name,
            documents: updatedDocuments,
        });
    };

    removeDocumentPreview = (docId: number) => {
        const updatedDocuments = { ...this.state.documents };
        delete updatedDocuments[docId];

        this.setState({
            uploadedDocumentName: '',
            documents: updatedDocuments,
        });
    };

    areAllDocumentsUploaded(sectionId: number): boolean {
        const sectionDocuments = configJSON.data.find((section: any) => section.id === sectionId)?.documents || [];
        return sectionDocuments.every((document: any) => this.state.documents[document.docId]?.uploaded);
    }
    // Customizable Area End
}
