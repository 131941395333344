// Customizable Area Start
//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from '../../../framework/src/StorageProvider';

export const configJSON = require("./config");

export interface ValidResponseType {
  data: object;
  message: Array<string>
}

export interface ValidTokenResponseType {
  meta: {
    token: string;
  }
}

export interface ErrorPayloadType {
  key: string;
}

export interface HasSuccessErrorTypeForWishlist {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}

export interface WishlistItems {
  id: string;
  attributes: {
    bx_block_products_product: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          price: number;
          product_description: null;
          category: [];
          sub_category: "Brice DuBuque";
          image: [
            "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWTA9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--90039475f581303f501bcfa09bff210d80d596ed/images.jpeg"
          ];
          status: string;
        }
      };
    };
  };
}

export interface InvalidResponseType {
  errors: {
    message: Array<string>
  }
}

export interface APIPayloadType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}
export interface Props {
  navigation: any;

}

interface S {

  wishlistArray: [];
  selectedItem: string;
  token: string;
  wishlistAllArray: [];
  hasSuccessErrorForWishlist: HasSuccessErrorTypeForWishlist;
  wishlistData: Array<WishlistItems>
}

interface SS {

}

export default class WishlistController extends BlockComponent<
  Props,
  S,
  SS
> {
  wishlistApiCallId: string = "";
  toggleWishlistApiCallId: string = "";
  wishlistAllApiCallId: string = "";
  deleteItem: string = ""
  getWishlistListApiCallId: string = ""
  postAddToCartApiCallId: string = ""
  postWishlistApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {

      wishlistArray: [],
      selectedItem: {},
      wishlistAllArray: [],
      token: '',
      hasSuccessErrorForWishlist: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      wishlistData: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.getWishlistList()
  }

  getToken = async () => {
    try {
      let value: string = await StorageProvider.get("token");
      this.setState({
        token: value
      })


      this.getAllWishlist()
      this.getWishlist()

    } catch (error) {
      alert(error);
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }
    }
  }




  getWishlist = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.wishlistApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWishlist
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  toggleWishlist = (itemGet) => {

    if (itemGet?.attributes?.add_to_wishlist)//istanbul ignore next
    {
      for (let item of this.state.wishlistArray)//istanbul ignore next
      {
        let idWishlist = item.attributes.item.data.id
        if (idWishlist == itemGet.id)//istanbul ignore next
        {
          const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
          };

          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.deleteItem = requestMessage.messageId;

          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getWishlist + "/" + item.id
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteAPiMethod
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        }
      }



    }
    else//istanbul ignore next
    {
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.toggleWishlistApiCallId = requestMessage.messageId;
      const httpBody = {

        item_id: itemGet?.attributes?.id

      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getWishlist
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }


  async itemSelect(value) {
    await StorageProvider.set("details", JSON.stringify(value));
    this.props.navigation.navigate("WishlistDetails")
  }

  getAllWishlist = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.wishlistAllApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetWishlist
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const token = (await StorageProvider.get("USER_TOKEN")) || "";
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.getWishlistListApiCallId) {
      this.setState({
        wishlistData: responseJson.data
      });
    }
    if (apiRequestCallId === this.postWishlistApiCallId) {
      this.snackBarSuccessApi(responseJson)
      this.getWishlistList()
    }
    if (apiRequestCallId === this.postAddToCartApiCallId) {
      this.snackBarSuccessApi(responseJson)
      this.getWishlistList()
    }
  };

  apiFailureCallBacks = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getWishlistListApiCallId) {
      this.snackBarErrorApi(responseJson);
    }
    if (apiRequestCallId === this.postWishlistApiCallId) {
      this.snackBarErrorApi(responseJson);
    }
    if (apiRequestCallId === this.postAddToCartApiCallId) {
      this.snackBarErrorApi(responseJson);
    }
  };

  snackBarErrorApi = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.errors.message[0]
      }
    });
  };

  snackBarSuccessApi = (responseJson: ValidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "success",
        isMessage: responseJson.message[0]
      }
    });
  };

  handleCloseSnack = () => {
    this.setState({
      hasSuccessErrorForWishlist: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    });
  };

  getWishlistList = async () => {
    this.getWishlistListApiCallId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getWishlistListAPIEndPoint
    });
  };

  addToCart = async (cartId: number) => {
    let body = {
      order_items: {
        product_id: cartId
      }
    }
    this.postAddToCartApiCallId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postAddToCartAPIEndPoint,
      body: body
    });
  };

  crossButton = async (wislistId: string) => {
    this.postWishlistApiCallId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.deleteAPiMethod,
      endPoint: configJSON.postWishlistAPIEndPoint + Number(wislistId),
    });
  };
}
// Customizable Area End
