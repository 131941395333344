Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.postSignUpAPIEndPoint = "account_block/accounts";
exports.nameblankError = "Please enter you're full name.";
exports.emailblankError = "Please enter you're email address.";
exports.emailValidation = "Please enter a valid email address.";
exports.checkBoxValidation = "Please accept the terms and conditions to proceed";
exports.check = "Check your Email";
exports.verification = "We sent a verification link to olivia@untitled.com";
exports.Manually = "Enter Code Manually";
exports.backTo = "Back to";
exports.logIn = "Log In";
exports.verified = "Email Verified";
exports.magically = "Your Email has been verified. Click below to log in magically.";
exports.continue = "Continue";

exports.businessDetails ="Business Details"
exports.enterTheDetails = "Enter the details of your Company"
exports.uploadDocuments = "Upload Documents"
exports.uploadImage = "Upload Image"
exports.companyNamePlaceholder = "Your Company Name";
exports.businessTypePlaceholder = "Select business type";
exports.businessRegistrationNumberPlaceholder = "Your business registration number";
exports.phoneNumberPlaceholder = "Your business contact number";

exports.companyNameLabel = "Company Name";
exports.businessTypeLabel = "Business Type";
exports.businessRegistrationNumber = "Business Registration Number";
exports.phoneNumberLabel = "Phone Number";
exports.companyName = "companyName"
exports.businessType = "businessType"
exports.required = "*"
exports.default = "default"

exports.businessAddressLabel = "Business Address";
exports.streetAddressPlaceholder = "Street address";
exports.cityPlaceholder = "City";
exports.stateProvincePlaceholder = "State/Province";
exports.postalCodePlaceholder = "Postal Code";
exports.countryLabel = "Country";
exports.selectCountryPlaceholder = "Select country";

exports.businessAddress ="businessAddress"
exports.city ="city"
exports.stateProvince  = "stateProvince"
exports.postalCode ="postalCode"
exports.country = "country"
exports.phoneNumber = "phoneNumber"
exports.uploadDocument = "Upload Document"

exports.footerText = "Whoops, looks like we need some more info!";
exports.backButtonText = "Back";
exports.nextButtonText = "Next";
exports.businessTypes =  [
    { id: 1, type: "sole" },
    { id: 2, type: "partnership" }
  ];
exports.countries = [
    { id: 1, country: "Philippines" },
    { id: 2, country: "India" },
    { id: 3, country: "Australia" }
  ];


exports.data = [
  {
      "id": 1111,
      "sectionTitle": "Business Registration and Permits",
      "sectionSubTitle": "Mayor’s Permit (LGU) and/or Barangay Cert",
      "documents": [
          {
              "docId": 1,
              "title": "Mayor’s Permit"
          },
          {
              "docId": 2,
              "title": "Barangay Certificate"
          }
      ]
  },
  {
      "id": 1112,
      "sectionTitle": "Tax Compliance",
      "sectionSubTitle": "Valid TIN issued by BIR. Up-to-date BIR registration and tax payments records, including VAT registration if applicable.",
      "documents": [
          {
              "docId": 3,
              "title": "Valid TIN issued by BIR"
          },
          {
              "docId": 4,
              "title": "Up-to-date BIR registration"
          }
      ]
  },
  {
      "id": 1113,
      "sectionTitle": "DTI or SEC Registration",
      "sectionSubTitle": "Sole proprietorships or partnerships, provide a Certificate of Business Name Registration issued by the DTI. Corporations, submit a valid Certificate of Incorporation issued by SEC",
      "documents": [
          {
              "docId": 5,
              "title": "Certificate of Business Name"
          },
          {
              "docId": 6,
              "title": "Valid Certificate of Incorporation"
          }
      ]
  },
  {
      "id": 1114,
      "sectionTitle": "Products/Service Compliance",
      "sectionSubTitle": "Relevant licenses, permits, or certifications specific to the products or services being offered within the PEZA economic zones, as required by relevant government agencies",
      "documents": [
          {
              "docId": 7,
              "title": "Relevant License"
          }
      ]
  },
  {
      "id": 1115,
      "sectionTitle": "Sanitary and Health Clearances",
      "sectionSubTitle": "Relevant licenses, permits, or certifications specific to the products or services being offered within the PEZA economic zones, as required by relevant government agencies",
      "documents": [
          {
              "docId": 8,
              "title": "Relevant License"
          }
      ]
  },
  {
      "id": 1116,
      "sectionTitle": "Local Zoning Clearance",
      "sectionSubTitle": "Relevant licenses, permits, or certifications specific to the products or services being offered within the PEZA economic zones, as required by relevant government agencies",
      "documents": [
          {
              "docId": 9,
              "title": "Relevant License"
          }
      ]
  },
  {
      "id": 1117,
      "sectionTitle": "Environment Compliance",
      "sectionSubTitle": "Relevant licenses, permits, or certifications specific to the products or services being offered within the PEZA economic zones, as required by relevant government agencies",
      "documents": [
          {
              "docId": 10,
              "title": "Relevant License"
          }
      ]
  },
  {
      "id": 1118,
      "sectionTitle": "Labor Compliance",
      "sectionSubTitle": "Relevant licenses, permits, or certifications specific to the products or services being offered within the PEZA economic zones, as required by relevant government agencies",
      "documents": [
          {
              "docId": 11,
              "title": "Relevant License"
          }
      ]
  },
  {
      "id": 1119,
      "sectionTitle": "Intellectual Property Rights (IPR)",
      "sectionSubTitle": "Relevant licenses, permits, or certifications specific to the products or services being offered within the PEZA economic zones, as required by relevant government agencies",
      "documents": [
          {
              "docId": 12,
              "title": "Relevant License"
          }
      ]
  },
  {
      "id": 1120,
      "sectionTitle": "Quality and Safety Standards",
      "sectionSubTitle": "Relevant licenses, permits, or certifications specific to the products or services being offered within the PEZA economic zones, as required by relevant government agencies",
      "documents": [
          {
              "docId": 13,
              "title": "Relevant License"
          }
      ]
  }
]



// Customizable Area End
