import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
export interface ValidResponseType {
  user: object;
  message: string;
}

export interface ValidTokenResponseType {
  meta: {
    token: string;
  }
}

export interface ErrorPayloadType {
  key: string;
}

export interface InvalidResponseType {
  errors: string;
}

export interface InputEventType {
  target: {
    value: string;
  }
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  userEmailID: string;
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  otpValues: string;
  seconds: number;
  otpErrors: {
    isOtpErros: boolean;
    otpErrorMessage: string
  };
  hasSuccessError: HasSuccessErrorType;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  interval: NodeJS.Timeout | undefined;
  postOtpApiCallId: string | null = null;
  postResendOtpApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      userEmailID: "olivia@untitled.com",
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      otpValues: "",
      seconds: 120,
      otpErrors: {
        isOtpErros: false,
        otpErrorMessage: ""
      },
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    this.interval = undefined;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        return;
      }

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.startCountdown();
  }

  async componentWillUnmount() {
    clearInterval(this.interval);
  }

  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      // console.log("entered is from forgot password!");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : "",
      };

      const httpBody = {
        data: data,
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    // console.log("requestMessage.id is: " + requestMessage.id);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  apiCallOtp = async (valueData: {
    contentType?: string,
    endPoint?: string,
    method?: string,
    type?: string,
    body?: {},
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    let header = {
      "Content-Type": contentType,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.user;
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiFailureCallBacks = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.postOtpApiCallId) {
      this.errorValidation(responseJson);
    }
    if (apiRequestCallId === this.postResendOtpApiCallId) {
      this.errorValidation(responseJson);
    }
  }

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.postOtpApiCallId) {
      this.snackBarOpen(responseJson);
    }
    if (apiRequestCallId === this.postResendOtpApiCallId) {
      this.snackBarOpen(responseJson);
      this.setState({
        seconds: 120,
      });
    }
  };

  startCountdown = () => {
    this.interval = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState((prevState) => ({
          seconds: prevState.seconds - 1,
        }));
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  };

  formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  resetTimer = async () => {
    let body = {
    }
    this.postResendOtpApiCallId = await this.apiCallOtp({
      contentType: configJSON.apiVerifyOtpContentType,
      method: configJSON.apiVerifyOtpMethod,
      endPoint: configJSON.resendOtpAPIEndPoint,
      body: body
    });
  };

  handleOTPChange = (otp: string) => {
    this.setState({ otpValues: otp });
  };

  handleGoBack = () => {
    window.history.back();
  };

  handleVerifyEmail = async () => {
    let body = {
      otp: this.state.otpValues
    }
    this.postOtpApiCallId = await this.apiCallOtp({
      contentType: configJSON.apiVerifyOtpContentType,
      method: configJSON.apiVerifyOtpMethod,
      endPoint: configJSON.postSignUpAPIEndPoint,
      body: body
    });
  };

  handleCloseSnack = () => {
    this.setState({
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    });
  };

  snackBarOpen = (responseJson: ValidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "success",
        isMessage: responseJson.message
      }
    });
  };

  errorValidation = (responseJson: InvalidResponseType) => {
    this.setState({
      otpErrors: {
        isOtpErros: true,
        otpErrorMessage: responseJson.errors
      }
    });
  };
  // Customizable Area End
}
