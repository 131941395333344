import React, { Component } from 'react';
// Customizable Area Start
import {
  withStyles,
  createStyles,
  Box,
  Typography,
} from "@material-ui/core";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes: Record<string, string>
  // Customizable Area End
}


class Footer extends Component<Props>{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const classes = this.props.classes;
    return (
      // Customizable Area Start
      <Box className={classes.footerOuterContainer}>
        <Box className={classes.footerContainer}>
          <Box className={classes.imageContainer}>
            <img className={classes.imageLogo} src={require('./logo.png')} alt="image" />
          </Box>
          <Box className={classes.infoAndCompanyContainer}>

            <Box className={classes.information}>
              <Typography className={classes.informationText}>Information</Typography>
              <Box className={classes.informationInnerContainer}>
                <Typography className={classes.infoCompanyContentText}>
                  Blog
                </Typography>
                <Typography className={classes.infoCompanyContentText}>
                  Shopping Policy
                </Typography>
                <Typography className={classes.infoCompanyContentText}>
                  Returns & Returns
                </Typography>
                <Typography className={classes.infoCompanyContentText}>
                  Contact Us
                </Typography>
              </Box>
            </Box>
            <Box className={classes.company}>
              <Typography className={classes.companyText}>Company</Typography>
              <Box className={classes.companyInnerContainer}>
                <Typography className={classes.infoCompanyContentText}>
                  About Us
                </Typography>
                <Typography className={classes.infoCompanyContentText}>
                  Privacy Policy
                </Typography>
                <Typography className={classes.infoCompanyContentText}>
                  Terms & Conditions
                </Typography>
                <Typography className={classes.infoCompanyContentText}>
                  Cookie Policy
                </Typography>
              </Box>

            </Box>
          </Box>
          <Box className={classes.aboutUsContainer}>
            <Typography className={classes.aboutUsText}>About Us</Typography>
            <Box className={classes.locationContainer}>
              <Box className={classes.imageLocationContainer}>
                <img className={classes.image} src={require('./image_location.svg')} alt="image" />
              </Box>
              <Typography className={classes.aboutUsContentText}>5039 Spinka Radial Suite 342</Typography>
            </Box>
            <Box className={classes.phoneContainer}>
              <Box className={classes.imagePhoneContainer}>
                <img className={classes.image} src={require('./image_phone.svg')} alt="image" />
              </Box>
              <Typography className={classes.aboutUsContentText}>+62 821 3647 7746</Typography>
            </Box>
            <Box className={classes.locationContainer}>
              <Box className={classes.imageMailContainer}>
                <img className={classes.image} src={require('./image-mail.svg')} alt="image" />
              </Box>
              <Typography className={classes.aboutUsContentText}>hello@ovaldesign.com</Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.footerBottomContainer}>
          <Typography className={classes.footerBottomCopyrightText}>
            Copyright &copy; <span className={classes.pezaezoneText}>PEZAEZONE</span> LLC. All rights reserved
          </Typography>
          <Typography className={classes.footerBottomPesoText}>
            Peso
          </Typography>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = createStyles({
  footerOuterContainer: {
    display: 'flex',
    padding: '72px 80px 32px 80px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '72px',
    background: '#F8EFE7',
    width: 'auto',
    flexWrap: 'wrap'
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
  },
  imageContainer: {
    display: 'flex',
    width: '97.427px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  infoAndCompanyContainer: {
    display: 'flex',
    width: '388px',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '120px'
  },
  information: {
    display: 'flex',
    width: '134px',
    flexDirection: 'column',
    gap: '16px'
  },
  company: {
    display: 'flex',
    width: '134px',
    flexDirection: 'column',
    gap: '16px'
  },
  informationInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    alignSelf: 'stretch'

  },
  companyInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    alignSelf: 'stretch'
  },
  aboutUsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch'
  },

  locationContainer: {
    display: 'flex',
  },

  phoneContainer: {
    display: 'flex',
  },

  mailContainer: {
    display: 'flex',
  },
  imageLogo: {
    width: '97.427px',
    height: '48px'
  },
  infoCompanyContentText: {
    color: '#475A69',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontHeight: '400',
    lineWeight: '22px'
  },
  informationText: {
    color: '#0F1E2A',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px'
  },
  companyText: {
    color: '#0F1E2A',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px'
  },
  aboutUsText: {
    color: '#121212',
    fontFamily: 'Montserrat',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.15px'
  },
  aboutUsContentText: {
    color: '#475A69',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px'
  },
  footerBottomContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    paddingTop: '24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #CBCBCB'
  },
  footerBottomCopyrightText: {
    color: '#000A12',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px'
  },

  pezaezoneText: {
    color: '#000A12',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px'
  },

  footerBottomPesoText: {
    color: '#000A12',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px'
  }
});

export default withStyles(styles)(Footer);
export { Footer }
// Customizable Area End