export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const emptyMyOrdersIcon = require("../assets/emptyMyOrdersIcon.png");
export const Search = require("../assets/Shape.png");
export const sortFilter = require("../assets/Iconnobackgroundsort.png");
export const SplashBg1 = require("../assets/background_blue.png");
export const NextArrow = require("../assets/expand_less_1.png");
export const Line = require("../assets/Line.png");
// export const AppBgImage = require("../../../mobile/assets/background_blue.png");
export const Trending = require("../assets/Trending.jpeg");
export const BackgroundAttachment = require("../assets/Iconnobackgroundattached.png");
export const LeftArrow = require("../assets/Shape1.png");
export const Star = require("../assets/Star.png");
export const Star1 = require("../assets/star1.png");
export const Notebook = require("../assets/notebook.png");
export const HomeImage = require("../assets/home.png");
export const OfficeImage = require("../assets/office.png");
export const OtherImage = require("../assets/other.png");
