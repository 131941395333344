import React, { Component } from 'react';
// Customizable Area Start
import { Snackbar } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { createStyles, withStyles } from "@material-ui/styles";

// Customizable Area End
export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}

export interface Props {
  // Customizable Area Start
  snackBarProps: HasSuccessErrorType;
  classes: Record<string, string>;
  handleCloseSnack: () => void | undefined
  // Customizable Area End
}

class SnackBarAlert extends Component<
  Props
> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const style = this.props.classes;
    return (
      // Customizable Area Start
      <>
        {this.props.snackBarProps.isOpen && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.props.snackBarProps.isOpen}
            onClose={this.props.handleCloseSnack}
            autoHideDuration={6000}
          >
            <Alert data-testid="snackBarCloseTestId" onClose={this.props.handleCloseSnack} severity={this.props.snackBarProps.isSeverity}>
              {this.props.snackBarProps.isMessage}
            </Alert>
          </Snackbar>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = () =>
  createStyles({
  });
export default withStyles(styles)(SnackBarAlert);
export { SnackBarAlert }
// Customizable Area End